<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="400">
            <v-card elevation="2">
                <v-card-title class="mb-4">
                    <!-- <span class="text-h4 font-weight-bold">课程{{ type }}</span> -->
                    <span class="text-h4 font-weight-bold">{{ title }}</span>
                </v-card-title>

                <v-card-text>
                    <!-- <div>
                        确认
                        <span style="color: purple">{{ type }}</span>
                        本课程内容么?
                    </div> -->
                    <div class="content">
                        <span v-html="highlight(text, keyword)"></span>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="confirm()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ConfirmDlg',
    props: ['title', 'text', 'keyword'],
    data() {
        return {
            dialog: false,
            params: null,
        };
    },
    methods: {
        openDlg(params = null) {
            this.params = params
            this.dialog = true
        },
        cancel() {
            this.params = null
            this.dialog = false
        },
        confirm() {
            this.$emit("confirm", this.params)
            this.dialog = false
        },
        highlight(text = '', keyword = '') {
            if (keyword == '') {
                return text
            }
            if (text.includes(keyword)){
                return text.replace(
                    keyword, 
                    // 这里是替换成html格式的数据，最好再加一个样式权重，保险一点
                    '<span style="color: #9c27b0 !important;">'+ keyword +'</span>'
                )
            }
            // 不包含的话还用这个
            else {
                return text
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    color: #555;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
}
</style>
