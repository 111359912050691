<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="950">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">选择学生</span>
                </v-card-title>
                <div class="dlg-content">
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="datas"
                        item-key="uid"
                        :items-per-page="10"
                        :items-per-page-options="[5, 10, 15, -1]"
                        class="elevation-2 row-pointer"
                        show-select
                        :single-select="singleSelect"
                        :hide-default-footer="datas.length <= 10"
                        :footer-props="{
                            showFirstLastPage: true,
                            itemsPerPageText: '每页行数',
                            pageText: '',
                            'items-per-page-all-text': '全部',
                        }"
                    >
                        <template v-slot:item.avatar="{ item }">
                            <v-avatar color="white" size="36">
                                <v-img
                                    :lazy-src="getAvatar(item.avatar)"
                                    :src="getAvatar(item.avatar)"
                                ></v-img>
                            </v-avatar>
                        </template>
                        <template v-slot:item.nickname="{ item }">
                            <div class="font-weight-medium">
                                <div class="active-name font-weight-medium" >
                                    {{ item.name }}
                                </div>
                            </div>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <div class="font-weight-medium">
                                <div class="active-name" >
                                    {{ getStudentName(item) }}
                                </div>
                            </div>
                        </template>
                        <template v-slot:item.number="{ item }">
                            <div>
                                {{ getStudentNumber(item.number) }}
                            </div>
                        </template>
                        <template v-slot:item.phone="{ item }">
                            <div>
                                {{ getStudentPhone(item.phone) }}
                            </div>
                        </template>
                    </v-data-table>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getAdminStudentList } from "@/api/admin";
import { getTypeColor } from '@/utils/common';
import { getAvatar } from "@/utils/util";

export default {
    name: "SelectStudentDlg",

    data: function () {
        return {
            dialog: false,

            headers: [
                { text: "头像", value: "avatar", align: "center", sortable: false, width: 60 },
                { text: "昵称", value: "nickname", align: "left", sortable: false, },
                { text: "学生姓名", value: "name",  align: "left", sortable: false, },
                { text: "账号", value: "number",   align: "left", sortable: false },
                { text: "联系方式", value: "phone", align: "center", sortable: false },
            ],
            datas: [],

            singleSelect: false,
            
            classId: '',
            selected: [],
        };
    },
    computed: {},
    created() {},
    activated() {
    },
    mounted() {
    },
    watch: {},
    methods: {
        getAvatar,
        getTypeColor,
        getStudentName(item) {
            if (item.real_name != "") {
                return item.real_name
            }
            return item.name
        },
        getStudentNumber(number) {
            if (number == "") {
                return "暂无"
            }
            return number
        },
        getStudentPhone(phone) {
            if (phone == "") {
                return "未填写"
            }
            return phone
        },
        loadData() {
            getAdminStudentList().then((response) => {
                // console.log(response.data);
                this.datas = response.data;

            }).catch(function (err) {
                console.log(err);
            });
        },
        openDlg(classId = '', selected = []) {
            this.classId = classId
            this.selected = selected

            this.loadData();
            this.dialog = true
        },
        cancel() {
            this.dialog = false
        },
        submit() {
            this.$emit("submit", {
                classId: this.classId,
                selected: this.selected
            })
            this.dialog = false
        },
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.dlg-content {
    width: 100%;
    padding: 0 30px;
}
</style>
